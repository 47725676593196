import { Link } from '@remix-run/react';

import Button from '@ux/ui/Button';

import hero from '~/common/assets/hero.png';
import bgPattern from '~/common/assets/bg-pattern.png';
import analytics from '~/common/assets/analytics.jpg';
import distributor from '~/common/assets/distributor.png';

import useSite from '~/common/hooks/useSite';

export default function Index() {
  const { site } = useSite();

  return (
    <div className="relative bg-gray-50 font-jakarta">
      <div className="absolute top-0 right-0 overflow-hidden lg:inset-y-0 h-[900px]">
        <img className="w-auto h-full" src={bgPattern} alt="" />
      </div>

      <header className="relative py-4 md:py-6">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="flex items-center justify-between">
            <div className="flex-shrink-0">
              <Link to="/" className="flex rounded outline-none focus:ring-1 focus:ring-gray-900 focus:ring-offset-2">
                <img className="w-auto h-10" src={site?.logoUrl} alt="Private label logo" />
              </Link>
            </div>

            <div className="flex lg:ml-16 lg:items-center lg:justify-center lg:space-x-10">
              <div className="hidden lg:flex items-center space-x-12">
                <Link
                  to="#about"
                  className="text-base font-medium text-gray-900 transition-all duration-200 rounded focus:outline-none hover:text-opacity-50"
                >
                  About us
                </Link>

                <Link
                  to="#features"
                  className="text-base font-medium text-gray-900 transition-all duration-200 rounded focus:outline-none hover:text-opacity-50"
                >
                  Features
                </Link>

                <Link
                  to="#distributor"
                  className="text-base font-medium text-gray-900 transition-all duration-200 rounded focus:outline-none hover:text-opacity-50"
                >
                  Distributors
                </Link>
              </div>
              <div className="flex gap-3">
                <Link to="#join">
                  <Button className="font-medium sm:block hidden" variant="secondary">
                    Contact us
                  </Button>
                </Link>
                <Link to="/login" prefetch="viewport">
                  <Button className="font-medium">Login</Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </header>

      <section className="relative py-12 sm:py-16 lg:pt-20 lg:pb-36">
        <div className="mx-auto">
          <div className="grid grid-cols-1 gap-y-8 lg:items-center lg:grid-cols-2 sm:gap-y-20 xl:grid-cols-4">
            <div className="text-center xl:col-span-2 lg:text-left xl:ml-[calc((100vw-1216px)/2)] px-6 sm:px-8 xl:px-0">
              <div className="max-w-sm mx-auto sm:max-w-md md:max-w-full">
                <h1 className="text-4xl font-bold leading-tight text-gray-900 sm:text-5xl sm:leading-tight lg:text-6xl lg:leading-tight mb-3 font-jakarta">
                  Empower Your Business with {site?.name}
                </h1>
                <p className="text-lg">
                  Elevate your business with {site?.name}—your go-to platform for personalized eCommerce. Sell directly or through
                  distributors with the flexibility and tools you need to thrive.
                </p>
              </div>
              <Link to="#join">
                <Button className="text-xl font-medium px-5 h-auto py-3 mt-8 mx-auto lg:mx-0">Get Started Today</Button>
              </Link>
            </div>

            <div className="xl:col-span-2">
              <img className="w-full ml-auto max-w-[1000px]" src={hero} alt="" />
            </div>
          </div>
        </div>
      </section>

      <section className="py-12 sm:py-24 bg-white">
        <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
            <div className="text-center">
              <h3 className="text-6xl font-bold mb-4 text-gray-900">32%</h3>
              <h4 className="text-2xl font-semibold mb-4 text-gray-900">Increase in Sales</h4>
              <p className="text-lg text-gray-500 font-medium">
                Experience up to 32% more sales by empowering your distributors with personalized eCommerce platforms that maximize their
                reach and efficiency.
              </p>
            </div>
            <div className="text-center">
              <h3 className="text-6xl font-bold mb-4 text-gray-900">28%</h3>
              <h4 className="text-2xl font-semibold mb-4 text-gray-900">Higher Revenue Per Customer</h4>
              <p className="text-lg text-gray-500 font-medium">
                Drive more revenue per customer with subscription models and targeted promotions, increasing average order values by 28%
              </p>
            </div>
            <div className="text-center">
              <h3 className="text-6xl font-bold mb-4 text-gray-900">40%</h3>
              <h4 className="text-2xl font-semibold mb-4 text-gray-900">Faster Checkout Process</h4>
              <p className="text-lg text-gray-500 font-medium">
                Speed up sales with a 40% faster checkout process, both online and in-person, thanks to our streamlined mobile app and
                integrated card reader.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="py-12 sm:py-24 " id="about">
        <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-10 items-center">
            <div>
              <h3 className="text-6xl font-bold text-gray-900 mb-7">Who we are?</h3>
              <p className="text-gray-700 leading-7">
                {site?.name} is a comprehensive SaaS platform that helps businesses build and manage their eCommerce websites, equipped with
                features to support distributor networks. From product management to sales tracking, our platform provides everything you
                need to grow your business and empower your distributors.
              </p>
            </div>

            <div>
              <img src={analytics} alt="Analytics" />
            </div>
          </div>
        </div>
      </section>

      <section className="py-12 bg-white sm:py-16 lg:py-20" id="features">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-bold leading-tight text-gray-900 sm:text-4xl xl:text-5xl ">
              Powerful Features at Your Fingertips
            </h2>
            <p className="text-xl leading-7 text-gray-700 mt-4">Tools to Scale Your Business</p>
          </div>

          <div className="grid grid-cols-1 mt-10 text-center sm:mt-16 sm:grid-cols-2 sm:gap-x-12 gap-y-12 md:grid-cols-3 md:gap-0 xl:mt-24">
            <div className="md:p-8 lg:p-14">
              <svg className="mx-auto" width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M45 29V23C45 10.85 35.15 1 23 1C10.85 1 1 10.85 1 23V29"
                  stroke="#161616"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M13 29H1V41C1 43.209 2.791 45 5 45H13V29Z"
                  fill="#D4D4D8"
                  stroke="#161616"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M45 29H33V45H41C43.209 45 45 43.209 45 41V29Z"
                  fill="#D4D4D8"
                  stroke="#161616"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <h3 className="mt-12 text-xl font-bold text-gray-900 ">Product Management</h3>
              <p className="mt-5 text-base text-gray-700 ">Easily add, edit, and manage your products.</p>
            </div>

            <div className="md:p-8 lg:p-14 md:border-l md:border-gray-200">
              <svg className="mx-auto" width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M27 27H19V45H27V27Z"
                  stroke="#161616"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9 37H1V45H9V37Z"
                  fill="#D4D4D8"
                  stroke="#161616"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M45 17H37V45H45V17Z"
                  fill="#D4D4D8"
                  stroke="#161616"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5 17L15 7L23 15L37 1"
                  stroke="#161616"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path d="M28 1H37V10" stroke="#161616" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              <h3 className="mt-12 text-xl font-bold text-gray-900 ">Subscription Model</h3>
              <p className="mt-5 text-base text-gray-700 ">Offer one-time purchases or subscription-based products.</p>
            </div>

            <div className="md:p-8 lg:p-14 md:border-l md:border-gray-200">
              <svg className="mx-auto" width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M41 1H1V41H41V1Z"
                  stroke="#161616"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M18 7H7V20H18V7Z"
                  stroke="#161616"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M18 26H7V35H18V26Z"
                  stroke="#161616"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M35 7H24V35H35V7Z"
                  fill="#D4D4D8"
                  stroke="#161616"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <h3 className="mt-12 text-xl font-bold text-gray-900 ">Distributor Networks</h3>
              <p className="mt-5 text-base text-gray-700 ">Empower your distributors with personalized eCommerce platforms.</p>
            </div>

            <div className="md:p-8 lg:p-14 md:border-t md:border-gray-200">
              <svg className="mx-auto" width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9.66667 25H6C3.23858 25 1 27.2386 1 30V37C1 39.7614 3.23858 42 6 42H36C38.7614 42 41 39.7614 41 37V30C41 27.2386 38.7614 25 36 25H31.8333C30.2685 25 29 26.2685 29 27.8333C29 29.3981 27.7315 30.6667 26.1667 30.6667H15.3333C13.7685 30.6667 12.5 29.3981 12.5 27.8333C12.5 26.2685 11.2315 25 9.66667 25Z"
                  fill="#D4D4D8"
                />
                <path d="M9 9H33" stroke="#161616" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M9 17H33" stroke="#161616" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path
                  d="M1 25H13V31H29V25H41"
                  stroke="#161616"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M37 1H5C2.79086 1 1 2.79086 1 5V37C1 39.2091 2.79086 41 5 41H37C39.2091 41 41 39.2091 41 37V5C41 2.79086 39.2091 1 37 1Z"
                  stroke="#161616"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <h3 className="mt-12 text-xl font-bold text-gray-900 ">Wholesale Selling</h3>
              <p className="mt-5 text-base text-gray-700 ">Manage bulk sales and pricing.</p>
            </div>

            <div className="md:p-8 lg:p-14 md:border-l md:border-gray-200 md:border-t">
              <svg className="mx-auto" width="46" height="42" viewBox="0 0 46 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M30.562 18.4609C30.0511 17.9392 29.4292 17.5392 28.7426 17.2907C28.0559 17.0422 27.3221 16.9516 26.5956 17.0256C25.8692 17.0996 25.1687 17.3362 24.5462 17.718C23.9237 18.0998 23.3952 18.6169 23 19.2309C22.6049 18.6167 22.0764 18.0995 21.4539 17.7176C20.8315 17.3357 20.1309 17.099 19.4044 17.025C18.6779 16.951 17.944 17.0417 17.2573 17.2903C16.5706 17.5389 15.9488 17.939 15.438 18.4609C14.5163 19.4035 14.0002 20.6695 14.0002 21.9879C14.0002 23.3063 14.5163 24.5722 15.438 25.5149L23 33.1999L30.564 25.5159C31.485 24.5726 32.0004 23.3064 32 21.988C31.9997 20.6696 31.4835 19.4037 30.562 18.4609Z"
                  fill="#D4D4D8"
                  stroke="#161616"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M41 41H5C3.93913 41 2.92172 40.5786 2.17157 39.8284C1.42143 39.0783 1 38.0609 1 37V1H17L22 9H45V37C45 38.0609 44.5786 39.0783 43.8284 39.8284C43.0783 40.5786 42.0609 41 41 41Z"
                  stroke="#161616"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <h3 className="mt-12 text-xl font-bold text-gray-900 ">Customer Engagement</h3>
              <p className="mt-5 text-base text-gray-700 ">Chat with customers directly from your admin panel.</p>
            </div>

            <div className="md:p-8 lg:p-14 md:border-l md:border-gray-200 md:border-t">
              <svg className="mx-auto" width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M25 7C34.941 7 43 15.059 43 25C43 34.941 34.941 43 25 43C15.059 43 7 34.941 7 25"
                  stroke="#161616"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M19 1C9.059 1 1 9.059 1 19H19V1Z"
                  fill="#D4D4D8"
                  stroke="#161616"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <h3 className="mt-12 text-xl font-bold text-gray-900 ">Analytics & Reporting</h3>
              <p className="mt-5 text-base text-gray-700 ">Get detailed insights into sales, inventory, and customer behavior.</p>
            </div>
          </div>
        </div>
      </section>

      <section id="distributor">
        <div className="mx-auto grid grid-cols-1 md:grid-cols-2 gap-20">
          <div className="xl:ml-[calc((100vw-1216px)/2)] pt-20 px-6 sm:px-8 xl:px-0">
            <h3 className="text-2xl font-bold leading-tight text-gray-900 sm:text-3xl xl:text-4xl ">
              Empower Your Distributors to Drive Growth
            </h3>
            <p className="leading-7 text-gray-700 mt-10 mb-12">
              {site?.name} takes your eCommerce business to the next level by enabling a robust distributor network. With our platform, each
              distributor gains the tools they need to create their own eCommerce site, manage their inventory, and optimize their sales.
              This not only expands your reach but also creates a powerful, decentralized sales force that works together to grow your
              brand.
            </p>

            <h4 className="text-xl font-bold leading-tight text-gray-900 xl:text-2xl ">Why Having Distributors Matters</h4>

            <p className="leading-7 text-gray-700 mt-4 mb-12">
              By leveraging a network of distributors, your business can tap into new markets and demographics that might be difficult to
              reach through traditional channels. Distributors bring their own local knowledge, customer bases, and marketing efforts,
              amplifying your brand&apos;s presence and increasing sales.
            </p>

            <ul className="list-disc pl-10 text-gray-700 leading-7 space-y-4">
              <li>
                <span>Localized Marketing:</span> Distributors understand their local markets better than anyone else, allowing for targeted
                marketing strategies that resonate with regional customers.
              </li>
              <li>
                <span>Increased Sales Channels:</span> With each distributor managing their own eCommerce platform, your products are
                available in more places, boosting overall visibility and sales
              </li>
              <li>
                <span>Flexible Pricing:</span> Distributors can set their own prices, offering them the ability to adjust to market demands,
                which helps maintain competitiveness and profitability.
              </li>
            </ul>
          </div>

          <div>
            <img src={distributor} alt="Distributor" />
          </div>
        </div>
      </section>

      <section className="py-12 sm:py-24 bg-white" id="join">
        <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl text-center">
          <h4 className="text-2xl font-bold leading-tight text-gray-900 sm:text-3xl xl:text-4xl ">Ready to Get Started?</h4>
          <p className="leading-7 text-gray-700 mt-4 text-xl max-w-2xl mx-auto mb-10">
            Take Your Business to the next level with {site?.name}. Join the growing number of businesses that trust {site?.name} for their
            eCommerce. Get started today and see how our platform can help you achieve your goals.
          </p>

          <div className="flex items-center border rounded-md border-gray-400 p-2 shadow-lg max-w-lg mx-auto">
            <input placeholder="Enter your email address" className="mb-0 border-white w-full pl-3 focus:outline-none" />
            <Button>Get Started</Button>
          </div>
        </div>
      </section>

      <footer className="py-12 sm:py-16 bg-gray-900 text-white">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="flex items-center justify-between">
            <div className="flex-shrink-0">
              <Link to="/" className="flex rounded outline-none focus:ring-1 focus:ring-gray-900 focus:ring-offset-2">
                <img className="w-auto h-10 grayscale invert contrast-200" src={site?.logoUrl} alt="Private label logo" />
              </Link>
            </div>

            <div className="flex lg:ml-16 lg:items-center lg:justify-center lg:space-x-10">
              <div className="hidden lg:flex items-center space-x-12">
                <Link
                  to="#about"
                  className="text-base font-medium transition-all duration-200 rounded focus:outline-none hover:text-opacity-50"
                >
                  About us
                </Link>

                <Link
                  to="#features"
                  className="text-base font-medium transition-all duration-200 rounded focus:outline-none hover:text-opacity-50"
                >
                  Features
                </Link>

                <Link
                  to="#distributor"
                  className="text-base font-medium transition-all duration-200 rounded focus:outline-none hover:text-opacity-50"
                >
                  Distributors
                </Link>
                <Link
                  to="/login"
                  className="text-base font-medium transition-all duration-200 rounded focus:outline-none hover:text-opacity-50"
                >
                  Login
                </Link>
              </div>
            </div>
          </div>
          <div className="border-t pt-5 mt-5">
            <p className="text-gray-300 text-sm">© Copyright 2024, All Rights Reserved by {site?.name}</p>
          </div>
        </div>
      </footer>
    </div>
  );
}
